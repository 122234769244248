// Axios Middleware
import Request from '../../../Middleware/Requests/RequestMaster';

function getCookieValue(name: any) 
{
    const jwtInfoPart = name.split('; ').find((row: any) => row.startsWith('JwtInfo='));

    // Step 2: Decode the JwtInfo value
    if (jwtInfoPart) {
        const jwtInfoValue = jwtInfoPart.split('=')[1]; // Get the part after 'JwtInfo='
        const decodedJwtInfo = decodeURIComponent(jwtInfoValue); // Decode the URL encoded string
    
        // Step 3: Parse the JSON
        const jwtInfo = JSON.parse(decodedJwtInfo.replace(/%22/g, '"')); // Replace encoded quotes with actual quotes
    
        // Step 4: Access the ACCESS_TOKEN
        const accessToken = jwtInfo.ACCESS_TOKEN;
        return accessToken;
    } else {
        console.log("JwtInfo not found in cookie.");
    }
}

// Get All Products for user
export const GetAllProductsUserId = async (userId:number) => {
    const response = await Request.get( `Market/UserProductByUserrId/${userId}` );
    return response.data;
}

export interface ProductIdUserId {
    UserId: any;
    ProductId: any;
}

// Get Product Using User Id and Product Id
export const GetAllProductsUsingUserIdAndProductId = async (payload: ProductIdUserId) => {
    const response = await Request.get( `Market/UserProductByUserIdProductId/${payload.UserId}/${payload.ProductId}` );
    return response.data;
}

// Create UserProduct Interface
export interface CreateUserProductInterface {
    PRODUCT_ID: string;
    IS_ACTIVE_Y_N: any;
    USER: number;
    VALID_FROM: any;
    VALID_TO: any;
    PAID_AMOUNT: number;
    USER_ACCOUNT_ID: number;
    USER_NAME: string;
    PASSWORD: string;
    paymenT_ID:number;
}

const headers = {
    'Authorization': 'Bearer ' + getCookieValue(document.cookie),
    'Content-Type': 'application/json'
};

// Create UserProduct
export const CreateUserProductAction = async ( Payload: CreateUserProductInterface) => {
    const response = await Request.post( `Market/UserProduct`, Payload, {headers} );
    //Navigate('/dashboard/categories', { replace: true });
    return response.data;
}

//select one user
export const SelectUserProductAction = async ( USER_PRODUCT_ID: any) => {
    const response = await Request.get( `Market/UserProductOne`, USER_PRODUCT_ID );
    //Navigate('/dashboard/categories', { replace: true });
    return response.data;
}

//select one user
export const SelectUserSubscriptionsAction = async ( USER_PRODUCT_ID: any) => {
    const response = await Request.get( `Market/UserSubscriptions/${USER_PRODUCT_ID}` );
    //Navigate('/dashboard/categories', { replace: true });
    return response.data;
}

//select one user Data
export const SelectUserDataAction = async ( USER_ACCOUNT_ID: any) => {
    const response = await Request.get( `Market/GetUserData/${USER_ACCOUNT_ID}` );
    //Navigate('/dashboard/categories', { replace: true });
    return response.data;
}